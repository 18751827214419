import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Datenschutzerklärung" />
    <h1>Datenschutzerklärung</h1>
    <p>Stand: 20.07.2020</p>

    <p>
      Diese Datenschutzerklärung ist gültig für die mobile Anwendung
      „Lehrerbuch” (im Folgenden „App“ genannt), die im Google Play Store und
      Apple App Store angeboten wird. In ihr werden Art, Zweck und Umfang der
      Datenerhebung im Rahmen der App-Nutzung erläutert.
    </p>

    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet
      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
      Zugriff durch Dritte ist nicht möglich.
    </p>
    <p>
      <b>Verantwortliche Stelle</b>
      <br />
      Dominique Klevinghaus
      <br />
      Mittelstraße 25
      <br />
      34576 Homberg
      <br />
      +49 151 5989 8889
      <br />
      inpho at codekommando . de
    </p>
    <p>
      Bei Installation dieser App werden keine personenbezogenen Daten erfasst.
    </p>

    <h3>Datenerhebung im Rahmen der App-Nutzung</h3>

    <p>
      Personenbezogene Daten werden im Rahmen der App-Nutzung nicht erhoben.
      Alle Daten bleiben auf dem Gerät und sind nur für den Inhaber einsichtig.
    </p>
    <h3>Zugriffsrechte der App</h3>
    <p>
      Zum Hinzufügen von Fotos der Schüler verlangt die App Zugriff auf Kamera
      und Fotoalbum. Sie können die App aber auch ohne benutzen.
    </p>

    <h3>Datenerfassung und Verschlüsselung</h3>
    <p>
      Die App speichert alle eingegebenen Daten verschlüsselt und ausschließlich
      auf dem Endgerät. Es werden weiter keine Sicherungen erstellt. Wir
      empfehlen mittels iTunes tägliche Backups, um Datenverlusten vorzubeugen.
    </p>

    <h3>Auskunft, Löschung, Sperrung</h3>

    <p>
      Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre
      gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und
      den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung
      oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit unter der im Impressum
      angegebenen Adresse an uns wenden.
    </p>

    <h3>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3>
    <p>
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
      Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
      der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
      unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
      Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
      <br />
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="noreferrer"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>

    <h2>
      Informationen über die Erhebung personenbezogener Daten auf unserer
      Webseite
    </h2>
    <p>
      Beim Besuch unserer Webseite werden keine personenbezogenen Daten erhoben.
      Es werden ausschließlich Daten erhoben, die von Ihrem Browser an unseren
      Server übermittelt werden. Diese Daten sind notwendig, damit Sie unsere
      Webseite angezeigt bekommen und auf ihr navigieren können. Rechtsgrundlage
      für die Erhebung der Daten ist Art. 6 Abs. 1 f)
      EU-Datenschutz-Grundverordnung. Im Einzelnen werden folgende Daten beim
      Besuch unserer Webseite erhoben:
      <ul>
        <li>IP-Adresse</li>
        <li>Art des Browsers, sowie dessen Sprache und Version</li>
        <li>Betriebssystem</li>
        <li>Zugriffstatus/http-Statuscode</li>
        <li>Datum und Uhrzeit der Anfrage, sowie die Zeitzone</li>
        <li>Inhalt der Anforderung und Webseite von der sie kommt</li>
        <li>Übertragene Datenmenge</li>
        <li>
          Die Löschung dieser Daten erfolgt, wenn diese nicht mehr erforderlich
          sind.
        </li>
      </ul>
    </p>

    <p>
      Sie haben uns gegenüber folgende Rechte, hinsichtlich der bei uns über Sie
      gespeicherten Daten:
      <ul>
        <li>Recht auf Berichtigung und Löschung</li>
        <li>Recht auf Auskunft</li>
        <li>Recht auf Einschränkung der Verarbeitung</li>
        <li>Recht auf Datenübertragbarkeit</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung</li>
        <li>
          Ihnen steht weiterhin ein Beschwerderecht beim Landesbeauftragten für
          den Datenschutz und die Informationsfreiheit in Hessen zu.
        </li>
      </ul>
    </p>
  </Layout>
)

export default PrivacyPage
